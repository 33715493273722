<template>
  <!-- Version: 1.8 -->
  <h2>1. Oyun açıqlaması</h2>
  <p>
    Highlight Games Soccerbet Oyunu oyun imkanları yaratmaq üçün matçlardan təsadüfi seçilmiş futbol məqamlarının unikal
    proqram simulyasiyasıdır.
  </p>
  <h2>2. Oyun açıqlaması və müddəti</h2>
  <p>
    Elektron mərc oyununun tək raundu 3 ilə 5 dəqiqə arasındadır. Bu, fikstür elanı, oyun həftəsi, preambula, oyundaxili
    ekran və nəticələrdən ibarətdir. Oyundaxili ekranın uzunluğu 1 dəqiqə olacaq. Bütün bir mövsüm 78 dəqiqədən 190
    dəqiqəyə qədər davam edə bilər. Bunun səbəbi həmin xüsusi liqadakı komandaların sayıdır; buna görə də oyun
    həftələrinin sayı dəyişəcək.
  </p>
  <p>
    Maraqlı futbol hadisələrinin seçimi statistik cəhətdən müstəqil, təsadüfi və gözlənilməzdir və Random Number
    Generator – RNG tərəfindən yaradılır.
  </p>
  <h2>3. Mərc növləri</h2>
  <h3>3.1 Dəqiq Qollar</h3>
  <p>
    Məqsədlərin dəqiq sayının mənası odur ki, bir matçda dəqiq sayda qol vurulmalıdır. Beləliklə, bu mərc dəyişikliyi o
    deməkdir ki, faktiki matç nəticəsindən asılı olmayaraq qolların dəqiq sayı proqnozlaşdırılmalıdır
  </p>
  <h3>3.2 Ev Komandasının Dəqiq Qolları</h3>
  <p>Meydan sahiblərinin oyunda vuracağı qolların dəqiq sayına mərc.</p>
  <h3>3.3 Səfər Komandanın Dəqiq Qolları</h3>
  <p>Qonaq komandanın oyunda vuracağı qolların dəqiq sayına mərc.</p>
  <h3>3.4 Hər iki komanda qol vuracaq</h3>
  <p>
    Hər iki komanda ən azı bir qol vurmalı, 1-1 və yuxarıdan istənilən nəticəni qazanmalıdır. &quot;Hər iki komanda qol
    vuracaq&quot; mərcləri oyunçulara iki komandadan yalnız birinin qol vura bildiyi halda sərfəli əmsallar əldə etmək
    üçün belə nəticələrdən istifadə etmək imkanı verir.
  </p>
  <h3>3.5 Dəqiq hesab</h3>
  <p>
    Dəqiq hesab, futbol matçının yekun nəticəsinin nə olacağına dair mərc etməkdir. Bir oyunda maksimum 6 qolla 28
    mümkün nəticə var.
  </p>
  <h3>3.6 Cüt şans</h3>
  <p>
    İkiqat şanslı mərc bir mərcdə futbol matçında mümkün olan üç nəticədən ikisini əhatə etməyə imkan verir. Ev sahibi
    komanda və heç-heçə = Əgər ev sahibi komanda qalib gəlsə və ya heç-heçə etsə, mərciniz qalibdir. Qonaq komanda və
    heç-heçə = Əgər qonaq komanda qalib gəlsə və ya heç-heçə etsə, mərciniz qalibdir.
  </p>
  <h3>3.7 Oyun Nəticəsi(1X2)</h3>
  <p>Ev sahibinin qalib gəlməsi (1), qonaq komandanın qalib gəlməsi (2) və ya heç-heçə (X) ilə bitən matça mərc.</p>
  <h3>3.8 Oyun Nəticəsi və Hər iki Komanda Qol Vuracaq (1X2 + Q/Q)</h3>
  <p>
    Matçın nəticəsinə mərc (1 ev sahibinin qələbəsi, X heç-heçə, 2 səfər komandasının qələbəsi) və hər iki komandanın
    qol vurub- vurmaması.
  </p>
  <ul>
    <li>1 + Q/Q - Ev sahibi komanda qalib gələcək və qarşılıqlı qol olacaq [misal üçün: 3-1].</li>
    <li>1 + Y/Q- Ev sahibi komanda qalib gəlir və qol vuran yeganə komandadır [misal üçün: 1-0].</li>
    <li>X + Q/Q - heç-heçəyə və qarşılıqlı qol olacaq. [misal üçün: 1-1, 2-2].</li>
    <li>X + Y/Q – Qol olmadan nəticə heç-heçə olacaq. [Yalnız 0-0].</li>
    <li>2 + Q/Q - Qonaq komanda qalib gələcək və qarşılıqlı qol olacaq [misal üçün: 1-2, 1-3].</li>
    <li>2 + Y/Q - Səfər komandası qalib gəlir və qol vuran yeganə komandadır [misal üçün: 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Oyun Nəticəsi və 1,5-dən çox/az qol</h3>
  <p>1X2 oyunun nəticəsinə və qolların ümumi sayının 1,5 qoldan az və ya yuxarı olmasına mərc.</p>
  <h3>3.10 Oyun Nəticəsi və 2.5-dən çox/az qol</h3>
  <p>1X2-dən etibarən matçın nəticəsi və qolların ümumi sayının 2,5 qoldan az və ya yuxarı olmasına mərc.</p>
  <h3>3.11 Ümumi Qollar (1-dən 2-ə qədər, 1-dən 3-ə qədər, 1-dən 4-ə qədər, 2-dən 3-ə qədər, 2-dən 4-ə qədər)</h3>
  <p>
    Oyunun yekun nəticə olaraq 1-dən 2-yə, 1-ə 3, 1-ə 4, 2-yə 3 və ya 2-dən 4-ə qədər ümumi qol olacağına mərc. Bu HƏ və
    ya XEYİR mərcidir.
  </p>
  <h3>3.12 Ev Komandasının Qolları 1.5-dən çox/az</h3>
  <p>Ev sahiblərinin matçda 1,5 qoldan çox və ya az vuracağına mərc.</p>
  <h3>3.13 Səfər Komanda Qolları 1.5-dən çox/az</h3>
  <p>Qonaq komandanın matçda 1,5 qoldan çox və ya az vuracağına mərc.</p>
  <h3>3.14 Ümumi Qollar 1.5, 2.5, 3.5, 4.5-dən çox/az</h3>
  <p>
    Matçda hər iki komandanın vurduğu qolların ümumi sayının 1,5, 2,5, 3,5 və ya 4,5-dən az və ya yuxarı olmasına mərc.
  </p>
</template>
